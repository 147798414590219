import objectPath from 'object-path'
import { computed, reactive, ComputedRef } from 'vue'
import cache from './cache'

interface StoreInterface<S> extends StoreSetup<S>{
  set?: <T>(state: S) => T;
  get?: <T>(state: S) => T;
}
interface StoreSetup<S> {
    name: string;
    state: S;
    cached?: boolean;
}
type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
export abstract class Store<S extends object> {
  private $tate: S
  private name: string
  private cached: boolean
  private bindings: {[path: string]: () => void} = {}
  constructor(options: StoreSetup<S>) {
    this.name = options.name
    this.cached = Boolean(options.cached)
    const cachedState = this.cached ? cache.get<S>(options.name) : {} as S
    let state = {...options.state, ...cachedState} as S
    if (this.onBeforeCreate) state = this.onBeforeCreate(state)
    this.$tate = reactive(state) as S
    this.onCreated && this.onCreated(state)
  }
  protected onBeforeCreate?(state: S): S
  protected onCreated?(state: S): void

  get state(): Readonly<S> {
    return this.$tate as Readonly<S>
  }
  clearCache() {
    cache.clear(this.name)
  }
  updateCache() {
    this.cached && cache.set(this.name, this.state)
  }
  get<T>(cb: (state: S) => T): ComputedRef<T> {
    return computed<T>(() => cb(this.state))
  }
  mutate<T>(cb: (state: S) => void): void {
    cb(this.state)
    this.updateCache()
  }
  mutatePath<T>(path: string, value: T): void {
    this.path(path).mutate(value)
    this.updateCache()
  }
  getStateAtPath<T>(path: string) {
    return this.path<T>(path).get()
  }
  path<T>(statePath: string | string[]) {
    return {
      get: () => objectPath.get<T | undefined>(this.state, statePath, undefined),
      mutate: (value: T) => objectPath.set<T>(this.state, statePath, value),
      delete: () => objectPath.del(this.state, statePath)
    }
  }

  set<T>(target: T, key: keyof typeof target, value: typeof target[typeof key]) {
    Object.assign(target, {[key]: {}})
    Object.assign(target, {[key]: value})
  }
}



