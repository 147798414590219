import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqtA8-QAXF1s-O0WahVALV2ggV5dDVPLg",
  authDomain: "ski-scanner.firebaseapp.com",
  projectId: "ski-scanner",
  storageBucket: "ski-scanner.appspot.com",
  messagingSenderId: "636671031685",
  appId: "1:636671031685:web:2e12d8841d6929af936cec"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);