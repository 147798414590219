const source = new EventSource("http://192.168.1.27:80/events")


export const onScan = (cb: (userId: string) => void) => {
  source.addEventListener<any>('scan', ev => {
    const userId = ev.data as string
    cb(userId)
  })
}

export const onConnect = (cb: (connected: boolean) => void) => {
  source.onmessage = ev => {
    if (ev.data == 'connected!') {
      cb(true)
    }
  }
}