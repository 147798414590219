import { Store } from './store.class'
import * as scanner from '@/services/scanner'
import * as database from '@/services/database'
import items from './items'

import { NewUser, Users } from './User'
import { OrderItem, Item } from './Order'

const audioBeep = new Audio('/beep.mp3')

interface GlobalStoreState {
  userId: string | null
  users: Users
  cart: OrderItem[]
  showCart: boolean
  items: Item[]
  loadingUsers: boolean
}

class GlobalStore extends Store<GlobalStoreState> {
  constructor() {
    super({
      name: 'user',
      cached: false,
      state: {
        userId: null,
        users: {},
        cart: [],
        showCart: false,
        items: items,
        loadingUsers: true
      }
    })
  }

  initialize () {
    scanner.onConnect(() => {
      console.log('Scanner connected!')
    })
    scanner.onScan(userId => {
      console.log('Card scanned:', userId)

      audioBeep.play()

      if (userId != this.state.userId) {
        this.setUserId(userId)
      }
      if (this.getCurrentUser()?.registered) {
        database.countScan(userId)
      }
    })

    // Sync with database
    database.syncUsers(users => {
      users.forEach(user => {
        this.mutate(state => state.users[user.id] = user)
      })
      this.mutate(state => state.loadingUsers = false)
    })
  }

  setUserId (userId: string | null) {
    this.mutate(state => state.userId = userId)
    this.emptyCart()
  }

  getUser (userId: string) {
    return this.state.users[userId] || null
  }

  emptyCart () {
    this.mutate(state => state.cart = [])
  }

  getCurrentUser () {
    if (this.state.userId) {
      if (this.state.users[this.state.userId]) {
        return this.state.users[this.state.userId]
      }
      return { id: this.state.userId, registered: false } as NewUser
    }
    return null
  }

  async createUser (userId: string, name: string) {
    console.log('USER CREATED', userId);
    return database.createUser(userId, name)
  }

  async setUserPhoto (userId: string, photo: string) {
    return database.setUserPhoto(userId, photo)
  }

  async setUserName (userId: string, name: string) {
    return database.setUserName(userId, name)
  }

  addToCart (item: Item, quantity = 1) {
    const itemIndex = this.state.cart.findIndex(i => i.id == item.id)
    if (itemIndex == -1) {
      this.mutate(state => state.cart.push({ ...item, quantity: quantity }))
    } else {
      this.mutate(state => state.cart[itemIndex].quantity += quantity)
    }
    this.mutate(state => state.showCart = true)
  }

  removeFromCart (item: Item) {
    const itemIndex = this.state.cart.findIndex(i => i.id == item.id)
    if (itemIndex != -1) {
      this.mutate(state => state.cart.splice(itemIndex,1))
    }
  }

  async order (): Promise<string | null> {
    if (this.state.userId && this.state.cart.length) {
      const order = await database.addOrder(this.state.userId, this.state.cart)
      this.emptyCart()
      this.mutate(state => {
        state.showCart = false
      })
      return order.id
    }
    return null
  }

  async addOrderPhoto (orderId: string, photo: string) {
    return database.setOrderPhoto(orderId, photo)
  }

  signOut () {
    this.setUserId(null)
  }
}

const store = new GlobalStore()

export default store