const get = <T>(id: string) => {
  return JSON.parse(localStorage.getItem(id) || '{}') as T
}
const set = <T>(id: string, value: T) => {
  localStorage.setItem(id, JSON.stringify(value))
}
const clear = (id: string) => {
  localStorage.removeItem(id)
}

export default {
  get,
  set,
  clear
}