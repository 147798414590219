import { Item } from "./Order"

const items: Item[] = [
  {
    id: 'tequilasunrise',
    name: "Tiroler Sunrise",
    alias: 'Tequila Sunrise',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'pinacolada',
    name: "Lavina Colada",
    alias: 'Piña Colada',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'isbjoern',
    name: 'Eisbär',
    alias: 'Isbjørn',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'brandbil',
    name: 'Kleiner Willi',
    alias: 'Williams mit Birne',
    alcohol: true,
    description: 'MIT GROßER BIRNE',
  },
  {
    id: 'brandbil',
    name: 'Heiße Liebe',
    alias: 'Brandbil',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'gintonic',
    name: "Gin'Atomic",
    alias: 'Gin & Tonic',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'gintonic2',
    name: "Super G&T",
    alcohol: false,
    alias: '"Gin" & Tonic (Alkoholfrei)',
    description: 'Dasdasd',
  },
  {
    id: 'sexonthebeach',
    name: 'Sex auf der Piste',
    alias: 'Sex on the Beach',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'filur',
    name: 'Franz Klammer',
    alias: 'Filur',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'schiwasser',
    name: "Schiwasser",
    alias: 'Schiwasser (Alkoholfrei)',
    alcohol: false,
    description: 'Dasdasd',
  },
  {
    id: 'schiefwasser',
    name: "Schiefwasser",
    alias: 'Vodka Schiwasser',
    alcohol: true,
    description: 'Dasdasd',
  },
  {
    id: 'shots',
    name: 'Schwarze Piste etc.',
    alias: 'Shots',
    alcohol: true,
    description: 'Dasdasd',
  },
]

export default items