<template>
<div class="modal-wrapper">
  <div class="modal">
    <slot></slot>
  </div>
</div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.modal-wrapper {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    display: inline-block;
    padding: 4rem;
    background: white;
    border: 8px solid #302db5;
    border-radius: 2rem;
    box-shadow: .5rem 1rem 0 0rem rgba(0,0,0,0.2);
  }
}
</style>