import { db } from "@/api/firebase"
import { OrderItem, Order } from "@/store/Order"
import { RegisteredUser } from "@/store/User"
import { collection, addDoc, setDoc, doc, onSnapshot, increment } from 'firebase/firestore'


// Sync
export const syncUsers = (cb: (users: RegisteredUser[]) => void) => {
  onSnapshot(collection(db, "users"), (snapshot) => {
    const users: RegisteredUser[] = [];
    snapshot.forEach((doc) => {
      users.push({ ...doc.data() as RegisteredUser, registered: true, id: doc.id })
    })
    cb(users)
  })
}

// Update a users name
export const setUserName = async (userId: string, name: string) => {
  return setDoc(doc(db, "users", userId), {
    name
  }, { merge: true })
}

// Create a user
export const createUser = async (userId: string, name: string) => {
  return setDoc(doc(db, "users", userId), {
    name: name.trim(),
    id: userId,
    scanCount: 0,
    checkedInAt: new Date(),
  } as RegisteredUser)
}

// Increment scan count
export const countScan = async (userId: string) => {
  return setDoc(doc(db, "users", userId), {
    scanCount: increment(1)
  }, { merge: true })
}

// Set user photo
export const setUserPhoto = async (userId: string, photo: string) => {
  addPhoto(userId, photo)
  return setDoc(doc(db, "users", userId), {
    photo
  }, { merge: true })
}

// Create order
export const addOrder = async (userId: string, items: OrderItem[]) => {
  return addDoc(collection(db, "orders"), {
    userId: userId,
    items: items
  } as Order)
}

export const setOrderPhoto = async (orderId: string, photo: string) => {
  await addPhoto('', photo, orderId)
  return setDoc(doc(db, "orders", orderId), {
    photo
  }, { merge: true })
}

// Sync orders
export const syncOrders = (cb: (orders: Order[]) => void) => {
  onSnapshot(collection(db, "orders"), (snapshot) => {
    const orders: Order[] = [];
    snapshot.forEach((doc) => {
      orders.push({ ...doc.data() as Order, id: doc.id })
    })
    cb(orders)
  })
}

// Process an order
export const setOrderProcessed = async (orderId: string, processed = true) => {
  return setDoc(doc(db, "orders", orderId), {
    processed
  }, { merge: true })
}



export interface Photo {
  userId?: string,
  orderId?: string,
  timestamp: Date,
  imgString: string,
  id: string
}

// Add a photo
export const addPhoto = async (userId: string, photo: string, orderId?: string) => {
  return addDoc(collection(db, "photos"), {
    userId: userId || '',
    orderId: orderId || '',
    timestamp: new Date(),
    imgString: photo
  })
}

// Sync photos
export const syncPhotos = (cb: (photos: Photo[]) => void) => {
  onSnapshot(collection(db, "photos"), (snapshot) => {
    const photos: Photo[] = [];
    snapshot.forEach((doc) => {
      photos.push({ ...doc.data(), id: doc.id } as Photo)
    })
    cb(photos)
  })
}